<template>
  <AboutComponent/>
<!--  <div class="container">-->
<!--    <div class="about-page-content">-->
<!--      <div class="box">-->
<!--        <div class="page-title">-->
<!--          <h1 class="title is-3 is-section-title mb-3">About Me</h1>-->
<!--        </div>-->
<!--        <div class="content"><p> I am a full-stack web and software developer focused on Laravel and Vue JS and-->
<!--          passionate about learning cutting-edge technologies and solving real problems. Having more than 3 years of-->
<!--          experience in full-stack web development and already built a good number of projects from scratch, I am also-->
<!--          experienced in working on a live project with good reviews from professionals. </p>-->
<!--          <p> I enjoy working in a challenging environment that focuses on best practices, quality work on web-->
<!--            application development, and allows me to study bleeding-edge technologies. Moreover, my main objective is-->
<!--            to offer high-quality service and I am here to provide the best services to people with my positive-->
<!--            attitude, character, and skills. As I follow best practices to achieve quality and speed with simple-->
<!--            scalability and maintainability, <strong class="tag is-danger is-light">I love to build Amazing-->
<!--              Things</strong>. </p></div>-->
<!--        <div class="content">-->
<!--          <h4>Some of my characteristics:</h4>-->
<!--          <ul>-->
<!--            <li>I am Highly skilled, goal-oriented, and self-motivated</li>-->
<!--            <li> I have versatile knowledge in Frontend and Backend Technology with having good working experience in-->
<!--              Laravel, VueJS, and NuxtJS.-->
<!--            </li>-->
<!--            <li>I am experienced in relational database management systems</li>-->
<!--            <li>I am familiar with advanced tools like Git, Webpack, &amp; Gulp</li>-->
<!--            <li>I have working experience in Agile methodology and tools like Jira &amp; Trello</li>-->
<!--            <li>I have superior knowledge in Linux systems administration</li>-->
<!--          </ul>-->
<!--        </div>-->
<!--        <div class="content">-->
<!--          <p> I believe that I have a good sense of understanding the desires of different client’s-->
<!--          requirements. I am always responsible for my work by providing the best quality service at the right time. I-->
<!--          can work as a team or individual, and I am stress-resistant. </p>-->
<!--          <p> I strive to create efficient, elegant solutions to real problems, in a way that is both enjoyable to-->
<!--            interact with and beautiful to look at. If you need quality work, please drop in a line anytime. I wish you-->
<!--            a great day! </p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>
<script>
import { defineComponent } from 'vue';
import AboutComponent from '@/components/About/About';

export default defineComponent({
  name: 'About',
  components: { AboutComponent }
});
</script>
<style lang="scss">
.about-page-content {
  padding: 3rem 0;
}
</style>
