<template>
  <div class="container container-narrow">
    <div class="about-page-content">
      <div class="page-title">
        <h1 class="title is-3 is-section-title mb-3">About Me</h1>
      </div>
      <div class="content">
        <p>I like to think of myself as a curious human, always eager to learn new things. Professionally, I’m a
          software
          engineer and technology enthusiast. excited about open-source software and cutting-edge technology. My passion
          is to develop, scale, and automate complex systems. Here are a few fun facts about me:</p>
        <ul>
          <li>My working experience spans from little startups to multi-billion dollar projects and companies.</li>
          <li>I love challenges and throw myself into difficult situations.</li>
          <li>I’m always interested in learning something new (in both tech and non-tech fields).</li>
        </ul>

        <h2>What is in my skill set?</h2>
        <p> I am a full-stack developer with extensive experience in Linux systems. I have played with a lot of tools
          and
          toys. Here are some notables :</p>
        <ul>
          <li>I have versatile knowledge in frontend and backend technology with good working experience in Laravel,
            WordPress, VueJS, and NuxtJS.
          </li>
          <li>knowledge of SQL and NoSQL database management systems.</li>
          <li>working knowledge of advanced tools such as Git, Webpack, and Gulp</li>
          <li>familiar with Agile methodologies and tools like Jira and Trello.</li>
          <li>possess advanced knowledge of Linux system administration</li>
        </ul>

        <h2> How can I add value to your business?</h2>
        <ul>
          <li> I have a very keen interest in growth hacking.</li>
          <li> I try to understand the business process and add value to both customers and the business.</li>
          <li> I assume ownership and lead by example.</li>
          <li> I understand the importance of shipping fast and capturing the market before competitors.</li>
          <li> I think outside the box and bring novelty to the table.</li>
        </ul>
        <p> I translate business requirements into software development practices and strive to achieve enhanced value
          creation for all of us!</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About'
};
</script>

<style scoped>

</style>
